const musicList = [
    {
        name: 'Hollow Realm',
        band: 'Kaozm',
        src: 'music/Kaozm_HollowRealm.mp3',
        bg: '/kaozm.jpg'
    },
    {
        name: 'Bleeding Artistry',
        band: 'Last Riot',
        src: 'music/LastRiot_ bleedingArtistry.mp3',
        bg: '/lastRiot.jpg'
    },
    {
        name: 'Anomia',
        band: 'Damnation of Gods',
        src: 'music/DamnationOfGods_Anomia.mp3',
        bg: '/damnationOfGods.jpg'
    },
    {
        name: 'Where Salvation Meets the Rot',
        band: 'Morrath',
        src: 'music/WhereSalvationMeetstheRot_Morrath.mp3',
        bg: '/morrath.jpg'
    },
    {
        name: 'Teoria Czasu',
        band: 'The Sparrow Grass',
        src: 'music/TheSparrowGrass_TeoriaCzasu.mp3',
        bg: '/thesparrowgrass.jpg'
    },
    {
        name: 'I Know',
        band: 'Sunstripes',
        src: 'music/Sunstripes_IKnow.mp3',
        bg: '/sunstripes.jpg'
    },
    {
        name: 'Opus Dei',
        band: 'Sothoris',
        src: 'music/Sothoris _OpusDei.mp3',
        bg: '/sothoris.jpg'
    },
    {
        name: 'Reset',
        band: 'Across the Void',
        src: 'music/AcrossTheVoid_Reset.mp3',
        bg: '/acrossTheVoid.jpg'
    },
    {
        name: 'Ile Wart',
        band: 'Schlafrock',
        src: 'music/Schlafrock _IleWart.mp3',
        bg: '/schlafrock.jpg'
    },
    {
        name: 'Błoto (prod. Mario Kontrargument)',
        band: 'Respo',
        src: 'music/Respo_Błoto.mp3',
        bg: '/respo.jpg'
    },
    {
        name: 'Depresja',
        band: 'Satori',
        src: 'music/Satori_Depresja.mp3',
        bg: '/satori.jpg'
    },
    {
        name: 'Inside out',
        band: 'Dimera',
        src: 'music/Dimera_InsideOut.mp3',
        bg: '/dimera.jpg'
    },
    {
        name: 'Filofobia',
        band: 'Damnation of Gods',
        src: 'music/DamnationOfGods_Filofobia.mp3',
        bg: '/damnationOfGods.jpg'
    }
]

export default musicList;