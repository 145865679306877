import React, { useState } from 'react'

function Privacy({ handleOpen }) {
    return (
        <div className='fixed top-20 right-10 bottom-10 left-10 bg-neutral-800 text-slate-200 overflow-scroll p-4'>
            <button className='hover:text-slate-600 bg-black p-2 rounded-lg relative' onClick={handleOpen}>ZAMKNIJ</button>
            <h2 className='text-xl font-bold text-center'>Polityka prywatności</h2>
            <h3 className='text-lg pt-2 font-semibold'>1. Wprowadzenie</h3>
            Dziękujemy za korzystanie z usług Tetra Wave Studio. Niniejsza polityka prywatności określa, w jaki sposób zbieramy, przechowujemy, używamy i chronimy dane osobowe naszych użytkowników.
            <h3 className='text-lg pt-2 font-semibold'>2. Rodzaje zbieranych danych</h3>
            Dane, które zbieramy, są podawane dobrowolnie przez użytkowników w formularzu kontaktowym i obejmują: imię oraz adres e-mail. Dane wykorzystujemy wyłącznie do świadczenia naszych usług.
            <h3 className='text-lg pt-2 font-semibold'>3. Linki do zewnętrznych serwisów</h3>
            Na naszej stronie internetowej mogą znajdować się linki do zewnętrznych serwisów, takich jak YouTube i media społecznościowe. Prosimy pamiętać, że korzystanie z tych linków może prowadzić do zbierania danych przez te zewnętrzne serwisy. Zalecamy zapoznanie się z polityką prywatności każdego z tych serwisów, aby zrozumieć, jakie informacje są zbierane i w jaki sposób są wykorzystywane.
            <h3 className='text-lg pt- font-semibold'>4. Cel działania</h3>
            Naszym celem jest zapewnienie użytkownikom pełnej prywatności podczas korzystania z naszych usług.
            <h3 className='text-lg pt-2 font-semibold'>5. Brak udostępniania danych osobom trzecim</h3>
            Nie udostępniamy żadnych danych użytkowników osobom trzecim.
            <h3 className='text-lg pt-2 font-semibold'>6. Bezpieczeństwo danych</h3>
            Chociaż nie zbieramy danych, stosujemy środki bezpieczeństwa, aby chronić naszą stronę internetową i aplikacje przed nieautoryzowanym dostępem i złośliwym oprogramowaniem.
            <h3 className='text-lg pt-2 font-semibold'>7. Kontakt</h3>
            Jeśli masz jakiekolwiek pytania dotyczące naszej polityki prywatności, prosimy o kontakt za pośrednictwem adresu e-mail: contact@tetrawavestudio.pl
            Dziękujemy za wybór Tetra Wave Studio.
        </div>
    )
}

function Footer() {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(!open)
    }
    return (
        <div className="bg-neutral-900 bottom-0 left-0 right-0 z-10 border-t-2 border-t-cyan-900">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-center h-16">
                    <div>
                        <div className="text-slate-200 m-auto text-center text-xs sm:text-sm">
                            Copyright ©2024 Tetra Wave Studio. Wszystkie prawa zastrzeżone.
                            <p className='hover:text-slate-600 cursor-pointer' onClick={handleOpen}>Polityka ptywatności</p>
                        </div>
                    </div>
                </div>
                {open ? <Privacy handleOpen={handleOpen} /> : null}
            </div>
        </div>
    )
}

export default Footer;