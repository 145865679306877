import React from "react";
import { useState } from "react";

const Nav = ({ language, switchLanguage }) => {
    const [isClick, setIsClick] = useState(false);

    const toggleNav = () => {
        setIsClick(!isClick);
    };

    return (
        <>
            <nav className="bg-neutral-900 fixed top-0 left-0 right-0 z-10 border-b-2 border-cyan-900">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <a href="#hero" className="text-white">
                                    <img src="/logo.png"
                                        width={60}
                                        height={60}
                                        alt="tetrawave logo"
                                        className="lg:hidden block" />
                                    <img src="/logoMobile.png"
                                        width={200}
                                        height={27}
                                        alt="tetrawave logo"
                                        className="lg:block hidden" />
                                </a>
                            </div>
                        </div>
                        <div className="hidden md:block">
                            <div className="ml-4 flex items-center space-x-4">
                                <a href="#about" className="transition delay-50 text-white hover:bg-white hover:text-black rounded-lg p-2">{language === 'pl' ? 'O NAS' : 'ABOUT US'}</a>
                                <a href="#portfolio" className="transition delay-50 text-white hover:bg-white hover:text-black rounded-lg p-2">PORTFOLIO</a>
                                {/* <a href="/" className="transition delay-50 text-white hover:bg-white hover:text-black rounded-lg p-2">GALERIA</a> */}
                                <a href="#offer" className="transition delay-50 text-white hover:bg-white hover:text-black rounded-lg p-2">{language === 'pl' ? 'OFERTA' : 'OFFER'}</a>
                                <a href="#faq" className="transition delay-50 text-white hover:bg-white hover:text-black rounded-lg p-2">FAQ</a>
                                <a href="#contact" className="transition delay-50 text-white hover:bg-white hover:text-black rounded-lg p-2">{language === 'pl' ? 'KONTAKT' : 'CONTACT'}</a>
                                <a href="https://www.facebook.com/tetrawavestudio" target="blank" className="transition delay-50 text-white hover:bg-white hover:text-black rounded-lg p-2"><img src="/facebook.svg" width={20} height={20} alt="facebook" /></a>
                                <a href="https://www.instagram.com/tetra.wave_studio/" target="blank" className="transition delay-50 text-white hover:bg-white hover:text-black rounded-lg p-2"><img src="/instagram.svg" width={20} height={20} alt="instagram" /></a>
                                <a href="https://www.youtube.com/@tetrawavestudio5538" target="blank" className="transition delay-50 text-white hover:bg-white hover:text-black rounded-lg p-2"><img src="/youtube.svg" width={20} height={20} alt="youtube" /></a>
                                <button onClick={switchLanguage} className="p-2"><img src={language === 'pl' ? "/engFlag.png" : '/plFlag.png'} width={20} height={20} alt="language" /></button>
                            </div>
                        </div>
                        <div className="md:hidden flex items-center">
                            <a href="https://www.facebook.com/tetrawavestudio" className="transition delay-50 text-white hover:bg-white hover:text-black rounded-lg p-2"><img src="/facebook.svg" width={20} height={20} alt="facebook" /></a>
                            <a href="https://www.instagram.com/tetra.wave_studio/" target="blank" className="transition delay-50 text-white hover:bg-white hover:text-black rounded-lg p-2"><img src="/instagram.svg" width={20} height={20} alt="instagram" /></a>
                            <a href="https://www.youtube.com/@tetrawavestudio5538" target="blank" className="transition delay-50 text-white hover:bg-white hover:text-black rounded-lg p-2"><img src="/youtube.svg" width={20} height={20} alt="youtube" /></a>
                            <button onClick={switchLanguage} className="p-2"><img src={language === 'pl' ? "/engFlag.png" : '/plFlag.png'} width={20} height={20} alt="language" /></button>
                            <button className="inline-flex items-center justify-center p-1 rounded-md text-white
                            hover:text-white focus:outlinc-none focus:ring-2 focus:ring-inset focus:ring-white"
                                onClick={toggleNav}
                            >
                                {isClick ? (
                                    <img
                                        src='/menuClose.svg'
                                        width={30}
                                        height={30}
                                        alt="menu close"
                                    />
                                ) : (
                                    <img
                                        src='/menuOpen.svg'
                                        width={30}
                                        height={30}
                                        alt="menu open"
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                    {isClick && (
                        <div className="md:hidden">
                            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                                <a href="#about" className="transition delay-50 block text-white hover:bg-white hover:text-black rounded-lg p-2">{language === 'pl' ? 'O NAS' : 'ABOUT US'}</a>
                                <a href="#portfolio" className="transition delay-50 block text-white hover:bg-white hover:text-black rounded-lg p-2">PORTFOLIO</a>
                                {/* <a href="/" className="transition delay-50 block text-white hover:bg-white hover:text-black rounded-lg p-2">GALERIA</a> */}
                                <a href="#offer" className="transition delay-50 block text-white hover:bg-white hover:text-black rounded-lg p-2">{language === 'pl' ? 'OFERTA' : 'OFFER'}</a>
                                <a href="#faq" className="transition delay-50 block text-white hover:bg-white hover:text-black rounded-lg p-2">FAQ</a>
                                <a href="#contact" className="transition delay-50 block text-white hover:bg-white hover:text-black rounded-lg p-2">{language === 'pl' ? 'KONTAKT' : 'CONTACT'}</a>
                            </div>
                        </div>
                    )}
                </div>
            </nav >
        </>
    )
}

export default Nav;