import React, { useState } from 'react';
import Modal from 'react-modal';

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
        color: "white",
        overflow: 'hidden',
        WebkitOverflowScrolling: 'touch',
        maxHeight: '80%',
        backgroundColor: 'black',
        aspectRatio: '16/9',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
};

const Square = ({ videoId, bg, name }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div>
            <div className="w-80 h-44" onClick={openModal}>
                <img
                    className='m-auto shadow-md hover:shadow-2xl hover:shadow-black rounded-lg cursor-pointer'
                    width={320}
                    height={180}
                    src={`${bg}`}
                    alt={`${name}`}
                />
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="YouTube Video Modal"
                style={customStyles}
                ariaHideApp={false}
            >
                <button onClick={closeModal}>ZAMKNIJ</button>
                <iframe
                    className='aspect-video'
                    title="YouTube Video"
                    width="90%"
                    height="90%"
                    src={`https://www.youtube-nocookie.com/embed/${videoId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </Modal>
        </div>
    );
};

export default Square;