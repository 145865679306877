import React, { useState, useEffect, useRef } from 'react';

const MusicPlayer = ({ tracks }) => {
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [trackDetails, setTrackDetails] = useState({
        name: '',
        band: '',
        bg: ''
    });

    useEffect(() => {
        setIsLoading(true);

        const img = new Image();
        img.src = trackDetails.bg;

        const handleImageLoad = () => {
            setIsLoading(false);
        };

        img.addEventListener('load', handleImageLoad);

        return () => {
            img.removeEventListener('load', handleImageLoad);
        };
    }, [trackDetails.bg]);

    useEffect(() => {
        setIsLoading(true);
    }, [currentTrackIndex]);

    useEffect(() => {
        const audio = audioRef.current;
        if (audio) {
            audio.src = tracks[currentTrackIndex].src;
            audio.onloadeddata = () => {
                setDuration(audio.duration);
                setCurrentTime(0);
            };
        }
    }, [currentTrackIndex, tracks]);

    useEffect(() => {
        if (tracks.length > 0) {
            setDuration(tracks[0].duration);
        }
    }, [tracks]);

    useEffect(() => {
        setCurrentTrackIndex(0);
        setTrackDetails({ name: tracks[0].name, band: tracks[0].band, bg: tracks[0].bg });
    }, [tracks]);

    useEffect(() => {
        const audio = audioRef.current;
        if (isPlaying) {
            audio.play();
        } else {
            audio.pause();
        }
    }, [isPlaying]);

    const updateTime = () => {
        const audio = audioRef.current;
        setCurrentTime(audio.currentTime);
        setDuration(audio.duration);
    };

    const onTimeUpdate = (e) => {
        setCurrentTime(e.target.currentTime);
    };

    const onSeek = (e) => {
        const audio = audioRef.current;
        audio.currentTime = e.target.value;
        setCurrentTime(audio.currentTime);
    };

    const togglePlay = () => {
        setIsPlaying(!isPlaying);
    };

    const selectTrack = (index, track) => {
        setCurrentTrackIndex(index);
        setIsPlaying(false);
        setTrackDetails({ name: track.name, band: track.band, bg: track.bg })
    };

    const playNextTrack = () => {
        const nextTrackIndex = (currentTrackIndex + 1) % tracks.length;
        setCurrentTrackIndex((currentTrackIndex + 1) % tracks.length);
        setIsPlaying(false);
        setTrackDetails({ name: tracks[nextTrackIndex].name, band: tracks[nextTrackIndex].band, bg: tracks[nextTrackIndex].bg });
    };

    const playPreviousTrack = () => {
        const previousTrackIndex = (currentTrackIndex - 1 + tracks.length) % tracks.length;
        setCurrentTrackIndex((currentTrackIndex - 1 + tracks.length) % tracks.length);
        setIsPlaying(false);
        setTrackDetails({ name: tracks[previousTrackIndex].name, band: tracks[previousTrackIndex].band, bg: tracks[previousTrackIndex].bg });
    };

    return (
        <div className='flex flex-col text-center md:text-left md:flex-row justify-evenly bg-neutral-900 rounded-xl text-white'>
            <div className='p-4 basis-1/2 text-center flex flex-col justify-between'>
                <div className='pt-4'>
                    <div className='md:h-96'>
                        {isLoading ? (
                            <p>Ładuję...</p>
                        ) : (
                            <img
                                className='m-auto shadow-xl shadow-neutral-700 pt-8'
                                width={250}
                                height={250}
                                src={trackDetails.bg}
                                alt="band"
                            />
                        )}
                        <h2 className='text-4xl my-4'>{trackDetails.band}</h2>
                        <h3 className='text-2xl my-2'>{trackDetails.name}</h3>
                    </div>
                    <audio
                        ref={audioRef}
                        src={tracks[currentTrackIndex].src}
                        onTimeUpdate={onTimeUpdate}
                        onLoadedData={updateTime}
                    />
                </div>

                <div className='pb-4'>
                    <div className='my-4 flex justify-center bg-slate-400 rounded-xl shadow-inner shadow-neutral-900'>
                        <button
                            className='mx-4 my-2 hover:scale-105'
                            aria-label="backward"
                            onClick={playPreviousTrack}>
                            <img src='/backward.svg' width={20} height={20} alt='backward' />
                        </button>
                        <button
                            className='mx-4 my-2 hover:scale-105'
                            aria-label="play-pause"
                            onClick={togglePlay}>
                            {isPlaying ?
                                <img src='/pause.svg' width={20} height={20} alt='pause' />
                                :
                                <img className='animate-pulse' src='/play.svg' width={20} height={20} alt='play' />}
                        </button>
                        <button
                            className='mx-4 my-2 hover:scale-105'
                            aria-label="forward"
                            onClick={playNextTrack}>
                            <img src='/forward.svg' width={20} height={20} alt='forward' />
                        </button>
                    </div>
                    <label htmlFor='songTime' />
                    <input
                        name="songTime"
                        type="range"
                        min={0}
                        max={duration}
                        value={currentTime}
                        onChange={onSeek}
                    />
                </div>
            </div>

            <div className='p-2 m-6 bg-neutral-800 rounded-md shadow-md shadow-black h-64 md:h-96 overflow-y-scroll self-center'>
                {tracks.map((track, index) => (
                    <div
                        className='m-2 p-2 hover:bg-slate-500 rounded-sm shadow-inner shadow-neutral-900 cursor-pointer'
                        key={index}
                        onClick={() => selectTrack(index, track)}>
                        {track.band} - {track.name}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MusicPlayer;