import MusicPlayer from "../musicPlayer/musicPlayer";
import musicList from "../musicPlayer/musicList";
import Youtube from "../youtube/youtube";


const Portfolio = () => {
    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-16" id='portfolio'>
                <h2 className='text-4xl md:text-6xl my-6 py-10'>Portfolio</h2>
                <MusicPlayer tracks={musicList} />
            </div>
            <div className="bg-neutral-100 mt-6">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                    <Youtube />
                </div>
            </div>
        </>
    );
}

export default Portfolio;