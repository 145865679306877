const About = ({ language }) => {

    return (
        <>
            <div className='pb-20'>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-16" id='about'>
                    <h2 className='text-4xl md:text-6xl my-6 py-10'>{language === 'pl' ? 'O nas' : 'About Us'}</h2>
                    <div className="flex flex-col md:flex-row w-full content-between gap-6">
                        <div className="self-center basis-2/3">
                            <img
                                src="/logoAbout.webp"
                                alt="logo About"
                            />
                        </div>
                        <div className="w-full self-center flex flex-col content-between lg:text-lg">
                            <div className='my-4 py-2'>
                                <strong>Tetra Wave Studio </strong>
                                {language === 'pl' ? `zostało założone w wyniku zamiłowania do muzyki oraz całego szeregu procesów,
                                które zachodzą podczas jej tworzenia. Posiadamy wieloletnie doświadczenie w realizacji nagrań
                                zarówno studyjnych jak również tych na potrzeby filmu i telewizji. W naszym studiu zapewniamy
                                przyjazną atmosferę, w której profesjonalnie zarejestrujemy twoją muzykę, jak również wykonamy
                                mix i mastering na najwyższym poziomie.` : `was founded as a result of a love of music and the whole range of processes that take place during its creation. We have many years of experience in making recordings, both studio recordings as well as those for film and television. In our studio we provide a friendly atmosphere in which we will professionally record your music, as well as perform mixing and mastering at the highest level.`}
                            </div>
                            <div className='my-4 py-2'>
                                {language === 'pl' ? `Ponadto zrealizujemy każdy etap produkcji dźwięku na
                                potrzeby filmu i telewizji, od zarejestrowania audio na planie, po udźwiękowienie i postprodukcje,
                                a także odzyskamy błędne zrealizowane lub uszkodzone nagranie.` : 'In addition, we will complete every stage of audio production for film and television, from on-set audio recording to sound mixing and post-production, as well as recovering a mistakenly realized or damaged recording.'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-neutral-900">
                <img
                    src="/crewWh.png"
                    alt="crew"
                    width={300}
                    height={300}
                    className='w-96 mx-auto'
                />
            </div>
            <div className='py-20 about bg-neutral-100'>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col sm:flex-row w-full justify-around lg:text-lg">
                        {/* Krzysztof */}
                        <div className='basis-1/3 overflow-hidden bg-black text-slate-200 rounded-xl shadow-xl mb-4'>
                            <div>
                                <img className="size-10/12 m-auto" src="/krzysztof.jpg" alt="realizator - Krzysztof"></img>
                            </div>
                            <div className="bg-neutral-900 p-4 h-full border-t-4 border-cyan-900">
                                <a href="https://www.facebook.com/krzysztof.kostencki" target="blank">
                                    <img className="m-auto transition delay-50 hover:scale-125" width={20} height={20} alt="facebook" src="/facebook.svg"></img>
                                </a>
                                <h3 className='text-center my-2 text-3xl p-2'><strong>Krzysztof Kostencki</strong></h3>
                                <p className="text-center p-2">
                                    {language === 'pl' ? `Założyciel i główny realizator w studiu.
                                    Ukończył Politechnikę Poznańską oraz Szkołę Muzyki Nowoczesnej we Wrocławiu.
                                    Posiada wieloletnie doświadczenie w zakresie realizacji zarówno nagrań studyjnych
                                    jak również dźwięku na planach filmowych.` : `Founder and main sound engineer in the recording studio. He graduated from the Poznan University of Technology and the School of Modern Music in Wroclaw. He has many years of experience in the realization of both studio recordings and sound on film sets`}
                                </p>
                            </div>
                        </div>
                        {/* Bartosz */}
                        <div className='basis-1/3 overflow-hidden bg-black text-slate-200 rounded-xl shadow-xl mb-4'>
                            <div>
                                <img className="size-10/12 m-auto" src="/bartek.jpg" alt="realizator - Bartek"></img>
                            </div>
                            <div className="bg-neutral-900 p-4 h-full border-t-4 border-cyan-900">
                                <a href="https://www.facebook.com/bartek.szczepaniak.71" target="blank">
                                    <img className="m-auto transition delay-50 hover:scale-125" width={20} height={20} alt="facebook" src="/facebook.svg"></img>
                                </a>
                                <h3 className='text-center my-2 text-3xl p-2'><strong>Bartosz Szczepaniak</strong></h3>
                                <p className="text-center p-2">
                                    {language === 'pl' ? `Drugi realizator w studiu.
                                    Od ponad 15 lat aktywnie działający basista na polskiej i zagranicznej scenie muzycznej.
                                    Posiada w swoim dorobku wiele zrealizowanych koncertów live oraz nagrań studyjnych.` : `Second sound engineer in the recording studio. For more than 15 years he has been an active bass player on the Polish and foreign music scene. He has completed many live concerts and studio recordings.`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default About;