import offersData from './offersData';
// import { Fade } from "react-awesome-reveal";


const OfferCard = ({ offer, language }) => {
    const { img, title, description, alt, color, descriptionEng, titleEng } = offer;

    return (
        <div className="offerCard md:basis-1/3 text-center flex flex-col my-10 px-4">
            <div className='mx-auto'>
                <img src={img} width={96} height={96} alt={alt} className='shadow-2xl rounded-full' />
            </div>
            <h3 className={`mt-2 mb-5 text-2xl font-bold underline ${color}`}>{language === 'pl' ? title : titleEng}</h3>
            <p className='my-1 px-6'>{language === 'pl' ? description : descriptionEng}</p>
        </div>
    )
}

const Offer = ({ language }) => {
    return (
        <>
            <div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-16" id='offer'>
                    <h2 className='text-4xl md:text-6xl my-6 py-10'>{language === 'pl' ? 'Oferta' : 'Offer'}</h2>
                    <div className="flex flex-col md:flex-row w-full md:content-between content-center flex-wrap justify-center lg:text-lg">
                        {offersData.map((offer, index) => (
                            // <Fade triggerOnce key={index} delay={100}>
                            <OfferCard key={index} offer={offer} language={language} />
                            // </Fade>
                        ))}
                    </div>
                </div>
            </div >
        </>
    );
}

export default Offer;