const faqsData = [
    {
        question: 'Ile kosztuje nagranie w studiu, bądź na planie?',
        answer: 'Cena uzależniona jest od czasu oraz skomplikowania utworu bądź materiału jaki przyjdzie nam nagrywać. W celu poznania szczegółowych cen zachęcamy do kontaktu poprzez formularz zgłoszeniowy.',
        questionEng: 'How much does it cost to record in a studio or on a set?',
        answerEng: 'The price depends on the time and complexity of the song or material we will record. In order to find out detailed prices, we encourage you to contact us via the application form.'
    },

    {
        question: 'Ile trwają nagrania w studiu?',
        answer: 'Wszystko zależy od przygotowania zespołu. Im lepiej zespół będzie przygotowany do sesji nagraniowej tym szybciej i sprawniej będzie ona przebiegać.',
        questionEng: 'How long does it take to record in the studio?',
        answerEng: 'It all depends on the preparation of the band. The better prepared the band is for the recording session, the faster and smoother it will go.'

    },
    {
        question: 'W jakich godzinach odbywają się nagrania?',
        answer: 'Dostosowujemy się do potrzeb klienta, realizujemy również nagrania w godzinach popołudniowych, wieczornych i nocnych.',
        questionEng: 'At what times do the recordings take place?',
        answerEng: "We adapt to the client's needs, we also make recordings in the afternoon, evening and night."

    },
    {
        question: 'Czy przygotowujemy pliki do tłoczni?',
        answer: 'Oczywiście. Jeżeli zdecydujecie się nagrać EP lub LP w naszym studiu po skończonej sesji otrzymacie od nas kompletny zestaw plików DDP, które będziecie mogli przekazać do tłoczni.',
        questionEng: 'Do we prepare files for pressing?',
        answerEng: 'Of course. If you decide to record an EP or LP in our studio after the session is over, you will receive a complete set of DDP files from us to submit to the pressing plant.'

    },
    {
        question: 'Czy świadczycie swoje usługi zdalnie?',
        answer: 'Oczywiście, usługi nie wymagające obecności w studiu możemy wykonać zdalnie.',
        questionEng: 'Do you provide your services remotely?',
        answerEng: 'Of course, services that do not require studio presence we can perform remotely.'

    },
    {
        question: 'Czy pomagacie w nagraniu teledysku?',
        answer: 'Współpracujemy z wieloma firmami, które profesjonalnie zajmują się realizacją teledysków .',
        questionEng: 'Do you help with the recording of the music video?',
        answerEng: 'We cooperate with many companies that professionally produce music videos.'

    },
    {
        question: 'Czy wykonujecie nagrania lektorskie lub nagrania audiobooków',
        answer: 'Oczywiście. W celu umówienia się na nagranie zachęcamy do kontaktu przez nasz formularz zgłoszeniowy.',
        questionEng: 'Do you make voiceover recordings or audiobook recordings?',
        answerEng: 'Of course. To schedule a recording, we encourage you to contact us through our application form.'

    },
    {
        question: 'Czym jest naprawa/restauracja audio i ile jest się w stanie odzyskać z uszkodzonego nagrania?',
        answer: 'Naprawa uszkodzonego nagrania polega na usunięciu zakłóceń powstałych podczas rejestracji lub wynikających z błędnie przeprowadzonego nagrania. Ilość odzyskanego materiału jest zawsze uzależniona od stopnia uszkodzenia materiału.',
        questionEng: 'What is audio repair/restoration and how much are you able to recover from a damaged recording?',
        answerEng: 'The repair of a damaged recording involves the removal of interference created during recording or from an erroneous recording. The amount of recovery is always dependent on the degree of damage to the signal.'
    },
    {
        question: 'Czy wykonujecie postprodukcję dla domów medialnych?',
        answer: 'Oczywiście. Od wielu lat wykonujemy postprodukcje dla największych domów medialnych w Polsce i zagranicą.',
        questionEng: 'Do you do post-production for media houses?',
        answerEng: 'Yes, there are two bedrooms available in the studio, which can sleep five people in total.'

    },
    {
        question: 'Czy w studiu można można nocować?',
        answer: 'Tak! W studiu do dyspozycji mamy dwie sypialnie, w których łącznie może spać pięć osób.',
        questionEng: 'Is it possible to spend the night in the studio?',
        answerEng: 'Yes, there are two bedrooms available in the studio, which can sleep five people in total.'

    },
    {
        question: 'Czy nocleg jest dodatkowo płatny?',
        answer: 'Nie, u Nas nocleg zawsze jest w cenie.',
        questionEng: 'Is there an additional fee for overnight stays?',
        answerEng: 'No, with Us the accommodation is always included in the price.'

    },
    {
        question: 'Czy w studiu jest łazienka?',
        answer: 'W studiu posiadamy dwie łazienki.',
        questionEng: 'Is there a bathroom in the studio?',
        answerEng: 'We have two bathrooms in the studio.'

    },
    {
        question: 'Czy w studiu jest prysznic?',
        answer: 'Tak, w studiu jest dostępny zarówno prysznic jak i wanna.',
        questionEng: 'Is there a shower in the studio?',
        answerEng: 'Yes, both a shower and a bathtub are available in the studio.'

    },
    {
        question: 'Czy w studiu dostępna jest kuchnia?',
        answer: 'Oczywiście. W naszym studiu mamy w pełni wyposażoną kuchnię, z której podczas sesji może korzystać bez ograniczeń.',
        questionEng: 'Is there a kitchen available in the studio?',
        answerEng: 'Of course. We have a fully equipped kitchen in our studio, which can be used freely during the session.'

    },
    {
        question: 'Czy na miejscu dostępne są restauracje/catering?',
        answer: 'Na miejscu do wyboru jest kilka restauracji/pizzerii, a aktualne oferty i cenniki znajdują się w studiu.',
        questionEng: 'Is on-site restaurant/catering available?',
        answerEng: 'There are several restaurants to choose from on site, and current listings and price lists are available at the studio. '

    },
    {
        question: 'Czy w pobliżu są sklepy spożywcze/stacje benzynowe?',
        answer: 'Tak, w pobliżu jest kilka większych sklepów spożywczych oraz stacja benzynowa.',
        questionEng: 'Are there grocery stores/petrol stations nearby?',
        answerEng: 'Yes, there are several major grocery stores and a gas station nearby.'

    },
    {
        question: 'Czy w studiu dostępny jest parking?',
        answer: 'Tak, na terenie studia dostępne są cztery miejsca parkingowe. Ponadto obok nieruchomości znajduję się ogólnodostępny parking.',
        questionEng: 'Is parking available at the studio?',
        answerEng: 'Yes, four parking spaces are available at the studio. In addition, there is a public parking lot next to the property.'

    },
    {
        question: 'Mam więcej pytań, gdzie mogę je zadać?',
        answer: 'Zapraszamy do kontaktu poprzez formularz zgłoszeniowy, adres mailowy: contact@tetrawavestudio.pl lub pod numerami telefonu: Krzysztof Kostencki  514 523 285 Bartosz Szczepaniak  661 690 242',
        questionEng: 'I have more questions, where can I ask them?',
        answerEng: 'You are welcome to contact us via the application form, email address: contact@tetrawavestudio.pl or at phone numbers: Krzysztof Kostencki - 514 523 285 Bartosz Szczepaniak - 661 690 242'
    }
]

export default faqsData;