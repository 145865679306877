import { useState } from "react";
import faqsData from "./faqsData";

const Faq = ({ faq, language }) => {
    const { question, answer, questionEng, answerEng } = faq
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div>
            <div onClick={handleOpen} className="p-2 hover:shadow-2xl md:text-lg max-w-screen-lg border rounded-lg my-4 flex justify-between m-auto  text-slate-200 bg-neutral-700 hover:bg-slate-600 cursor-pointer">
                <p className="p-4">{language === 'pl' ? question : questionEng}</p>
            </div>
            {isOpen &&
                <div className="max-w-screen-lg border rounded-lg -mt-2 p-4 m-auto bg-neutral-300 md:text-lg">
                    <p>{language === 'pl' ? answer : answerEng}</p>
                </div>
            }
        </div>
    );
}

const Faqs = ({ language }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(!isOpen)
    }
    return (
        <div className="bg-neutral-100 pb-5">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-16" id='faq'>
                <h2 className='text-4xl md:text-6xl my-6 pt-10'>FAQ</h2>
                <p className="text-center pb-6 md:text-xl">{language === 'pl' ? 'W tej sekcji znajdziesz odpowiedzi na najczęściej zadawane pytania. Kliknij by dowiedzieć się więcej.' : 'In this section you will find answers to frequently asked questions. Click to find out more.'}</p>
                <img onClick={handleOpen} src="/arrow.webp" className="m-auto cursor-pointer animate-bounce" alt="arrow down" />
                {isOpen &&
                    <div>
                        {faqsData.map((faq, index) => (
                            <Faq key={index} faq={faq} language={language} />))}
                    </div>
                }
            </div>
        </div>
    )
}

export default Faqs;
