const youtubeData = [
    {
        name: "KAOZM - HOLLOW REALM (Official Music Video)",
        url: "zxJC8jQu6w4?si=EQtcCritO6L5_j1j",
        bg: '/1.jpg'
    },
    {
        name: "Last Riot - Bleeding Artistry (Official Music Video)",
        url: "3bhaaZ6rgoc?si=oO--7-9v_PZvTQF1",
        bg: '/2.jpg'
    },
    {
        name: "TSG - Wieczność [OFFICIAL VIDEO]",
        url: "1OEMkhmmUnY?si=qK0eH1gcl8k6zChv",
        bg: '/3.jpg'
    },
    {
        name: "Sunstripes - Beneath (Rock/Post-Rock Full Album 2024)",
        url: "lnKPK2mZ2EU?si=LYj8kiX60IUWXYPL",
        bg: '/4.jpg'
    },
    {
        name: "Morrath (Pol) - Centuries of Blindness (Album 2023)",
        url: "GeroN1IgSNc?si=WMiIetyTaN6lsimK",
        bg: '/5.jpg'
    },
    {
        name: "Satori i Kazik - Oddział Beznadziejnych Przypadków",
        url: "eTNR7gwAt4A?si=XuB5vf2qygOZTRdq",
        bg: '/6.jpg'
    },
    {
        name: "KAOZM - Dual (Official music video)",
        url: "SUrHfSuoeUU?si=eEjryiv5ijMCMHr0",
        bg: '/7.jpg'
    },
    {
        name: "Across The Void - All For Nothing (Official Music Video)",
        url: "UNfV1HCJQqQ?si=pcbb8izeoOkMcP_n",
        bg: '/8.jpg'
    },
    {
        name: "Damnation of Gods- Hemofobia",
        url: "2D_2AvzhHNM?si=ZsJWaPNe8LrtLRNw",
        bg: '/9.jpg'
    },
    {
        name: "Hegemone | Live session in Szyba | 2023",
        url: "73yuZkZRKUo?si=VmF6QwxcXnQSKZon",
        bg: '/10.jpg'
    },
    {
        name: "BLACK VINE SESSIONS #2 | Asteriæ | 2024",
        url: "abNLb1TiAt0?si=sO4NZwElEyOwJJp5",
        bg: '/11.jpg'
    },
    {
        name: "SchlafRock - Ile Wart [OFFICIAL VIDEO]",
        url: "2zLpz0X1BNM?si=D9CWoAAN4V35QSVz",
        bg: '/12.jpg'
    },
    {
        name: "Dimera- Inside out (Official audio)",
        url: "hygb8WroTxU?si=a9cb0YJ5S18KvVOa",
        bg: '/13.jpg'
    },
    {
        name: "SOTHORIS - BLACK HORIZONS (DISSECTION TRIBUTE COVER)",
        url: "yG3Cf9J7et8?si=iDxkwEqyFPT2M7-j",
        bg: '/14.jpg'
    },
    {
        name: "Respo - Super Plan [SUPER PLAN EP]",
        url: "gnLX4Px-2Uw?si=0QdAqJksLKbmvEBj",
        bg: '/15.jpg'
    },
    {
        name: "Reflections - Samsara (Vocal Cover by Grobel)",
        url: "a-cGLfZAbeE?si=V4EUk9RE-6gkCz5O",
        bg: '/16.jpg'
    }
]

export default youtubeData;