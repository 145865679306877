import React from 'react';
import Square from './square';
import youtubeData from './youtubeData';

const Youtube = () => {

    return (
        <div className="flex flex-wrap gap-10 justify-center mt-10">
            {youtubeData.map((videoId, index) => (
                <Square key={index} videoId={videoId.url} bg={videoId.bg} name={videoId.name} />
            ))}
        </div>
    );
};

export default Youtube;