import HeroBtn from "../button/button";

const Hero = ({ language }) => {
    return (
        <>
            <div className="bg-black md:-mt-16 relative top-16 -z-10" id="hero">
                <div>
                    <img className="absolute top-1/3 w-9/12 h-9/12 left-2/4 -translate-x-2/4 z-10 max-w-full"
                        src="/tetraText.png"
                        alt="tetrawave text-logo"
                        width={1000}
                        height={200}
                    />
                    <video loop autoPlay muted className="object-cover w-full max-h-screen blur">
                        <source src={require("../../assets/video/tetraHeroWeb.webm")} type='video/webm' />
                    </video>
                </div>
            </div>
            <HeroBtn language={language} />
        </>
    )
}

export default Hero;