const offersData = [
    {
        img: '/offer1.png',
        alt: 'offer1',
        title: 'Produkcja',
        titleEng: 'Production',
        color: 'decoration-rose-600',
        description: 'pomożemy w zrealizowaniu nawet najbardziej skomplikowanych pomysłów',
        descriptionEng: 'we will help you realize even the most complex ideas'
    },
    {
        img: '/offer2.png',
        alt: 'offer2',
        title: 'Nagranie',
        titleEng: 'Recording',
        color: 'decoration-blue-600',
        description: 'profesjonalnie i precyzyjnie zarejestrujemy twój materiał',
        descriptionEng: 'we will professionally and accurately record your material'
    },
    {
        img: '/offer3.png',
        alt: 'offer3',
        title: 'Edycja',
        titleEng: 'Editing',
        color: 'decoration-green-600',
        description: 'dopilnujemy, aby każde nagranie było poprawne i schludne',
        descriptionEng: "we'll make sure every recording is correct and neat"
    },
    {
        img: '/offer4.png',
        alt: 'offer4',
        title: 'Miks i Mastering',
        titleEng: 'Mixing and mastering',
        color: 'decoration-purple-600',
        description: 'nadamy twojemu nagraniu światowe brzmienie',
        descriptionEng: 'we will give your recording a world-class sound'
    },
    {
        img: '/offer5.png',
        alt: 'offer5',
        title: 'Postprodukcja na potrzeby filmu i TV',
        titleEng: 'Post-production for film and TV',
        color: 'decoration-teal-600',
        description: 'doprowadzimy twoje nagrania do standardów wymaganych przez największe stacje telewizyjne oraz wytwórnie filmowe',
        descriptionEng: 'we will bring your recordings up to the standards required by major TV stations and film studios'
    },
    {
        img: '/offer6.png',
        alt: 'offer6',
        title: 'Udźwiękowienie',
        titleEng: 'Sound design',
        color: 'decoration-orange-600',
        description: 'wiarygodnie oddamy każdy aspekt dźwiękowej rzeczywistości w twoim filmie',
        descriptionEng: 'we will faithfully render every aspect of sound reality in your film'
    },
    {
        img: '/offer7.png',
        alt: 'offer7',
        title: 'Dźwięk na planie',
        titleEng: 'Sound on set',
        color: 'decoration-yellow-600',
        description: 'skrupulatnie zarejestrujemy każdy dźwięk do twojej produkcji',
        descriptionEng: 'we will meticulously record every sound for your production'
    },
    {
        img: '/offer8.png',
        alt: 'offer8',
        title: 'Naprawa audio',
        titleEng: 'Audio restoration',
        color: 'decoration-sky-600',
        description: 'uratujemy uszkodzone lub błędnie zrealizowane nagrania',
        descriptionEng: 'we will rescue damaged or miscast recordings'
    }
]

export default offersData;