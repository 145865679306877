import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import opinions from "./opiniotData";

export default function SimpleSlider({ language }) {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 8000,
        adaptiveHeight: true,
        pauseOnHover: true
    };
    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-16" id='faq'>
            <h2 className='text-4xl md:text-6xl my-6 py-10'>{language === 'pl' ? 'Opinie' : 'Opinions'}</h2>
            <div className="rounded-xl max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 bg-neutral-900 text-slate-200">
                <div className="slider-container">
                    <Slider {...settings}>
                        {opinions.map((opinion, idx) =>
                        (
                            <div key={idx} className="p-4">
                                <h3 className="text-2xl px-4 pt-4">{opinion.name}</h3>
                                <p className="px-4">⭐⭐⭐⭐⭐</p>
                                <p className="px-4 py-6">{opinion.content}</p>
                            </div>
                        )
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    );
}