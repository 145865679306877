const Button = ({ text, styl, href }) => {
    const btnStyleFull = "transition-all hover:bg-white hover:text-neutral-900 hover:border-neutral-900 cursor-pointer font-semibold rounded-xl bg-transparent border-2 border-white px-2 md:px-10 py-2 md:py-3 text-white"
    const btnStyleOutline = "transition-all hover:bg-transparent hover:text-white hover:border-white cursor-pointer font-semibold rounded-xl bg-white border-2 border-neutral-900 px-2 md:px-10 py-2 md:py-3 text-neutral-900"

    return (
        <a href={href} className={styl === "full" ? btnStyleFull : btnStyleOutline}>
            {text}
        </a >
    )
}

const HeroBtn = ({ language }) => {
    return (
        <div className="relative bottom-8 sm:-top-20 lg:-top-44 flex gap-4 md:gap-8 scale-50 sm:scale-100 justify-center justify-items-center flex-wrap">
            <Button text="PORTFOLIO" styl="full" href="#portfolio" />
            <Button text={language === 'pl' ? 'ZAREZERWUJ SESJĘ' : 'BOOK YOUR SESSION'} styl="outline" href="#contact" />
            <Button text={language === 'pl' ? 'OFERTA' : 'OFFER'} styl="full" href="#offer" />
        </div>
    )
}

export default HeroBtn;