const opinions = [
    {
        name: "Dan Earl Willis",
        content: "Pełen profesjonalizm, super ludzie i świetna atmosfera. Z pewnością nie jest to moja ostatnia wizyta w Tetra Wave. Gorąco polecam!!"
    },
    {
        name: "Przemek Święcicki",
        content: "Profesjonalizm, wzorowe podejście do klienta i świetnie brzmiące rezultaty, w które wkładane jest dużo pracy i serca. To właśnie jest Tetra Wave Studio."
    },
    {
        name: "Kacper Drzewiczewski",
        content: "Pełna profeska, zwłaszcza jeśli chodzi o podejście realizatora do nagrań - kontakt na najlepszym poziomie, polecanko! 💪"
    },
    {
        name: "Maksymilian Krasoń",
        content: "Wiecie po czym poznać profesjonalizm? Po tym, że profesjonalista przykłada do pracy serce, bierze na swoje ramiona odpowiedzialność za dzieło i nie pozwoli, by spod jego ręki i oka wyszło cokolwiek urągające jego fachowości. Jeśli chcecie na własne oczy, bez długich poszukiwań, zobaczyć fachowca, polecam uderzyć do Tetra wave."
    },
    {
        name: "Krzysztof Mazur",
        content: "Mieliśmy przyjemność nagrywać świeżo po uruchomieniu studia, zatem mogło jeszcze czegoś brakować. Tymczasem nam nie brakowało niczego. Operator hebla to pozytywny człowiek, z doświadczeniem, nastawiony aktywnie na współprace, poradę, kontruktywną krytykę ( również w swoją stronę ), pomoc, przewodnictwo. Jeśli czegoś nie wiesz, to on to wie, ty masz grać. Pełen profesjonalizm obsługi i na luzie. Jeśli ktoś się kiedyś natknął na realizatora z fochem, nastawionego na wklepanie śladów jak najszybciej, byle zgarną kasę i do widzenia, to tu to go nie spotka. Nagraliśmy z kapelą cały album i bedziemy kontynuować współpracę dopóki nam się nie znudzi. Przewiduje że w bardzo krótkim czasię studio bedzie jeszcze doinwestowane i bedzie oferowało jeszcze wieksze możliwości. Polecam !"
    },
    {
        name: "Tomasz Zaporowski",
        content: "Profesjonalne studio zrobione z przytupem. Specjalistów takich jak Krzysiek ze świecą szukać. Nie dość że wyrozumiały i cierpliwy to z takim kunsztem w głowie i rękach, że szok. Do tego spoko gość i nawet ja z moimi małymi pierdołami nigdy nie zostałem odrzucony a to co skleił zawsze mnie satysfakcjonowało. Szczerze polecam i sam pewno wrócę nie raz nie dwa"
    },
    {
        name: "Maciej Grobel Grobelny",
        content: "Pełna profeska!!! Wchodząc po raz pierwszy (jako typowy laik ) do studia miałem swoje własne wyobrażenie o samym sobie jako wokalista. Mając pewna wizje samego siebie Wiedziałem jak chce zabrzmieć na płycie. Realizator świetnie wytłumaczył mi jak to zrobić, co poprawić (!) aby tego dokonać Przekazał mi wiele wskazówek które finalnie dały efekt którego sam się nie spodziewałem- doskonale zrozumiał moje intencje i wyciągnął ze mnie więcej niż sam sądziłem że potrafię.Ogromna wiedza, umiejętności interpersonalne na najwyższym poziomie, spoko człowiek. 😎Na pewno wrócę !"
    },
    {
        name: "Mikołaj Rybka",
        content: "Pełen profesjonalizm. Spokojne podejście do pracy i zero stresu podczas pracy co jest bardzo ważne przy nagrywaniu. Realizator nie tylko czuwa nad tym, by wszystko było jak się należy, ale i doradzi w taki sposób, by kompozycja nabrała smaczków. Naprawdę z czystym sumieniem mogę polecić Tetra Wave Studio!"
    },
    {
        name: "Kamil Vento Nowak",
        content: "Świetne Studio! Panuje spokój i komfort pracy. Mega fajna współpraca nad kawałkami. Widać, że realizator zna się na rzeczy i jest bardzo pomocny przy kwestiach aranżacyjnych i brzmieniowych. Serdecznie polecam :D"
    },
    {
        name: "Wojtek Kowalczykiewicz",
        content: "Studio dobrze i profesjonalnie wyposażone. Ale sprzęt się sam nie obsłuży! Realizator wie do czego służy każdy potencjometr. W dodatku Krzysiek jest mega wyluzowanym człowiekiem, panuje domowa i luźna atmosfera. Polecam tego 'didżeja' ;D"
    },
    {
        name: "Karol Treder",
        content: "Polecam z całego serca. Profesjonalne i zaraz ludzkie podejście. To już moja druga sesja w tym studiu i nie wyobrażam sobie nagrywać gdzieś indziej."
    },
    {
        name: "Grzegorz Brylak",
        content: "Świetne studio nagrań zaraz obok Poznania. Krzysztof to doświadczony i kreatywny realizator, który pomógł nam znaleźć brzmienie naszych utworów i finalnie całej płyty. Bardzo sprawna komunikacja i dobra energia ;) Dzięki Krzychu. bardzo polecam!"
    },
    {
        name: "Miłosz Maik",
        content: "Pełen profesjonalizm przy jednocześnie luźnym klimacie. Cudotwórca w kwestii brzmienia i znawca sprzętu jakich mało. Przy okazji bardzo spoko ziomek i dobry motywator do ćwiczeń. Jak nagrywać ciężko brzmiącą płytę to tylko tam"
    },
    {
        name: "The Sparrow Grass",
        content: "Fantastyczna, ale też profesjonalna atmosfera podczas nagrywania utworów. Krzysztof świetnie zna się na rzeczy i służy pomocą, radą i opinią. Zdecydowanie polecamy!"
    },
    {
        name: "Mieszko Jankowski",
        content: "Świetna oraz luźna atmosfera przy zachowaniu należytego profesjonalizmu. Realizator z szeroką wiedzą służący pomocą i kreatywnymi radami. Z pewnością wrócę do Tetra Wave!"
    },
    {
        name: "meefo92",
        content: "Bardzo profesjonalne studio nagraniowe, które zapewnia indywidualne podejście do klienta i każdego problemu. Dla Tetra Wave Studio nie ma rzeczy niemożliwych! Polecam 100%"
    },
    {
        name: "Szymon Werwa",
        content: "Pełen profesjonalizm, świetna atmosfera i indywidualne podejście do muzyków. Polecam!"
    },
    {
        name: "Bartek Ziebart",
        content: "Wszystko na najlepszym poziomie od obsługi, po sprzęt i atmosferę podczas nagrywania! 💪🏽💪🏽💪🏽 Kawa tez była super 😉"
    },
    {
        name: "Krzysztof Czechowski",
        content: "Kilka razy montowali mi audio do filmu i zawsze wszystko było bardzo dobre, polecam gorąco!"
    }
];

export default opinions;