import React, { useState } from 'react';

function ContactForm({ language }) {
    const [notification, setNotification] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);

        try {
            const response = await fetch(form.action, {
                method: form.method,
                body: formData
            });

            if (response.ok) {
                setNotification('Wiadomość została wysłana pomyślnie.');
                setTimeout(() => {
                    setNotification('');
                }, 3000);
            } else {
                throw new Error('Wystąpił błąd podczas wysyłania wiadomości.');
            }
        } catch (error) {
            setNotification(error.message);
            setTimeout(() => {
                setNotification('');
            }, 3000);
        }
    };

    return (
        <div className='bg-neutral-900' id='contact'>
            <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-16'>
                <h2 className='text-4xl md:text-6xl mt-6 py-10 text-slate-200'>{language === 'pl' ? 'Kontakt' : 'Contact'}</h2>
                <div className='text-slate-200 mb-6 flex gap-12 flex-col sm:flex-row'>
                    <div>
                        <h3 className='text-2xl'>{language === 'pl' ? 'Adres:' : 'Address:'}</h3>
                        <p>Ul. Poznańska 27</p>
                        <p>62-025 Kostrzyn</p>
                    </div>
                    <div>
                        <h3 className='text-2xl'>{language === 'pl' ? 'Telefon:' : 'Phone:'}</h3>
                        <p>Krzysztof Kostencki: +48 514 523 285</p>
                        <p>Bartosz Szczepaniak: +48 661 690 242</p>
                    </div>
                    <div>
                        <h3 className='text-2xl'>e-mail:</h3>
                        <p>contact@tetrawavestudio.pl</p>
                    </div>
                    <div>
                        <h3 className='text-2xl'>Social media:</h3>
                        <div className='flex'>
                            <a href="https://www.facebook.com/tetrawavestudio" target="blank" className="transition delay-50 text-white hover:bg-white hover:text-black rounded-lg p-2"><img src="/facebook.svg" width={20} height={20} alt="facebook" /></a>
                            <a href="https://www.instagram.com/tetra.wave_studio/" target="blank" className="transition delay-50 text-white hover:bg-white hover:text-black rounded-lg p-2"><img src="/instagram.svg" width={20} height={20} alt="instagram" /></a>
                            <a href="https://www.youtube.com/@tetrawavestudio5538" target="blank" className="transition delay-50 text-white hover:bg-white hover:text-black rounded-lg p-2"><img src="/youtube.svg" width={20} height={20} alt="youtube" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-8 text-slate-200 flex flex-col sm:flex-row gap-8 justify-around">
                <iframe className='rounded-xl min-h-96' title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2434.474690637315!2d17.21970367655882!3d52.398072572029456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47045b0802857637%3A0xbc475a77008cccbb!2sTetra%20Wave%20Studio!5e0!3m2!1spl!2spl!4v1707850099996!5m2!1spl!2spl" width="100%" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                <form onSubmit={handleSubmit} className='basis-7/12 flex flex-col gap-3 rounded-xl p-4 bg-neutral-800' action="mail.php" method="POST">
                    <label htmlFor='name'>{language === 'pl' ? 'Imię' : 'Name'}</label>
                    <input className='text-black' type="text" name="name" required />
                    <label htmlFor='email'>e-mail</label>
                    <input className='text-black' type="email" name="email" required />
                    <label htmlFor='msg'>{language === 'pl' ? 'Wiadomość' : 'Message'}</label>
                    <textarea className='text-black' rows={6} cols={40} name="msg" required></textarea>
                    <input className='self-start' type='checkbox' name='agreement' required />
                    <label htmlFor='agreement' className='text-xs text-slate-200'>{language === 'pl' ? 'Wyrażam zgode na przetwarzanie danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z wysyłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem poinformowany, że przysługuje mi prawo odstępu od swoich danych, możliwości ich poprawiania, żądania zaprzestania ich przetwazania.' : 'I consent to the processing of personal data in accordance with the Personal Data Protection Act in connection with sending an inquiry via the contact form. Providing data is voluntary, but necessary to process the inquiry. I have been informed that I have the right to withdraw my data, to correct it, and to demand that its processing be stopped.'}</label>
                    <button type="submit" className='bg-slate-700 hover:bg-slate-800 p-4'>{language === 'pl' ? 'WYŚLIJ' : 'SEND'}</button>
                </form>
            </div>
            {notification && <div className='flex justify-center text-xl'
                style={{
                    background: notification.includes('pomyślnie') ? 'green' : 'red'
                }}>{notification}</div>}
        </div>
    )
}

export default ContactForm;