import { useLanguage } from './components/languageContext/LanguageContext';
import Nav from './components/nav/nav';
import Hero from './components/hero/hero';
import About from './components/about/about';
import Offer from './components/offer/offer';
import Portfolio from './components/portfolio/portfolio';
import Faq from './components/faq/faq';
import Footer from './components/footer/footer';
import ContactForm from './components/contactForm/contactForm';
import SimpleSlider from './components/opinionCard/opinion';

function App() {
  const { language, switchLanguage } = useLanguage();
  return (
    <div className="App">
      <Nav language={language} switchLanguage={switchLanguage} />
      <Hero language={language} />
      {/* <button onClick={switchLanguage}>Zmień język</button> */}
      <About language={language} />
      <Portfolio language={language} />
      <Offer language={language} />
      <Faq language={language} />
      <SimpleSlider language={language} />
      <ContactForm language={language} />
      <Footer language={language} />
    </div>
  );
}

export default App;
